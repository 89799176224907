// src/pages/blog


import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/App/Layout';
import SEO from '../components/App/SEO';
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import BlogCard from '../components/BlogContent/BlogCard';

const BlogPage = ({ data, location }) => (
    <Layout location={location} env={data.site.siteMetadata.env}>
        <Navbar />
        <SEO 
            postTitle='Blog | BlueMail App' 
            postDescription='BlueMail Blog'
            postImage='/img/OG/og_image_Blog.png'
            postURL='blog'
            postSEO
        />
        <div className="post-feed-container bg-nav">
            <BlogCard data={data.allMarkdownRemark.edges.sort((a , b) => new Date(b.node.frontmatter.date) - new Date(a.node.frontmatter.date))} />
        </div>
        <Footer />
    </Layout>
);

export const query = graphql`
    query BlogIndexQuery {
        allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "blog" } }}
        ) {
            edges {
                node {
                    id
                    html
                    excerpt
                    frontmatter {
                        publishToProd
                        templateKey
                        path
                        title
                        category
                        metaTitle
                        date
                        image
                        blogTitle
                        description
                        metaDescription
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                env
                imagesPrefix
            }
        }
    }
`;

export default BlogPage;
